<template>
<div id="LevelExamIntroReciprocal">  
  <Header/>
  <body class=" bg-white">
    <nav class="navbar bg-white fixed-top nicole-noshadow">
      <div class="container">
        <a class="navbar-brand text-bold me-0 col-4  text-card-big" rel="tooltip" data-placement="bottom" @click="onBack">
          <img class="avatar avatar-sm" src="assets/img/icon-back-black.png">
        </a>            
        <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom">
          
        </a>             
        <a class="navbar-brand font-weight-bolder me-0 text-right col-4" >
        </a> 
      </div>
    </nav>
    <div class="nicole-member-center">
      <div class="container text-center mt-3 ">
        <div class="minpicmb">
          <img src="assets/img/test2.png" class="testapic">  
        </div>
        <div class="text-card-xxl text-yellow text-bolder mb-3 mb-bd-5">測驗正式開始</div>               
        <div class="text-card-name text-muted text-simbold "> 每題只能作答一次</div>
          <div class="col-12">
            <div class="flex-wrapper ">
              <div class="single-chart">
                <svg viewBox="0 0 36 36" class="circular-chart green">
                  <path class="circle-bg" d="M18 2.0845
                  a 15.9155 15.9155 1 0 1 0  31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                  <path class="circle" stroke-dasharray="100, 0" d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                  <text x="18" y="23" class="percentage">{{time}}</text>
                </svg>
              </div>
            </div>
          </div>
        <div class="text-card-xxl text-success text-bolder">加油!</div>  
      </div> 
    </div>
    <div class="nicolespace"></div>  
  </body>
</div>
</template>


<script>
import Header from '@/components/Header.vue'
import { oltConsole , oAC } from "@/lib/oltModule/initClient";

export default {
    name: 'LevelExamIntroReciprocal',
    components: {
        Header,
    },
    data () {
      return { 
        // api setting
        setDebugMode:false ,
        apiDebug: false,
        debugMode: false,
        apiName: "",

        // data

        timer: null,
        time: 3,

      }
    },
    methods: {
      setOnBack(){
          this.$JsBridge.callHandler('setOnback', '', this.onBack);
      },
      onBack() {
        if (this.$route.params.back != undefined && this.$route.params.back != "" ) {
          this.$router.push(this.$route.params.back);
        } else {
          this.$router.push({
            name: "Home"
          });
        }
      },
      Check_Time(){
        if(this.time <= 0){
          clearInterval(this.timer);
          this.$router.push({
            name: "LevelExamStart"
          });
          return;
        }
        this.time -= 1;
        this.time = this.time % 60;
        
      },
    },
      
    mounted() {
      this.timer = setInterval(this.Check_Time, 1000);
    },
    created(){  
      if (this.$JsBridge.isAndroid) {
          this.setOnBack();
      }
    },
    beforeDestroy() {
      clearInterval(this.timer);
    }
};
</script>

